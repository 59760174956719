import React from 'react'
import ConsultingForm from '../ConsultingForm/ConsultingForm';

const Consultinencey = (props) => {
    return (
        <section className={`contact-section ${props.contactClass}`}>
            <div className="content-area clearfix">
                <div className="contact-info-col">
                    <div className="contact-info">
                        <ul>
                            <li>
                                <i className="fi flaticon-home-3"></i>
                                <h4>Büro Adresimiz</h4>
                                <p>Aşıkpaşa Mah. Dr. İsmail Yağız Cad. Başak Apt. No:11/9 Merkez/KIRŞEHİR</p>
                            </li>
                            <li>
                                <i className="fi flaticon-email"></i>
                                <h4>Email Adresimiz</h4>
                                <p>bilgi@busrakocaogluersoy.com</p>
                            </li>
                            <li>
                                <i className="fi flaticon-support"></i>
                                <h4>Telefon</h4>
                                <p>+90 553 135 01 64</p>
                            </li>
                            <li>
                                <i className="fi flaticon-clock"></i>
                                <h4>Çalışma Saatlerimiz</h4>
                                <p>P.Tesi-Cuma: 09:00 – 17:00</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="contact-form-col">
                    <div className="section-title-s2">
                        <div className="icon">
                            <i className="fi flaticon-balance"></i>
                        </div>
                        <span>Randevu Talep Formu</span>
                        <h2>Davanız mı var? <br />Hemen Bize Ulaşın. </h2>
                        <p>Bize yazmanızdan memnuniyet duyarız. En kısa zamanda size geri dönüş sağlayacağız.</p>
                    </div>

                    <div className="contact-form">
                        <ConsultingForm />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Consultinencey;