import React from 'react'
import abimg from '../../images/about-2.jpg'
import abimg2 from '../../images/about-3.jpg'
import { Link } from 'react-router-dom';


const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    
    return (
        <section className="about-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-4 col-12">
                        <div className="left-col">
                            <div className="section-title-s5">
                                <span>Hakkında</span>
                                <h2>Avukat Büşra Kocaoğlu Ersoy</h2>
                                <p>2013 yılında Kırşehir Anadolu Öğretmen Lisesi'nden başarıyla mezun olan, üniversite
tercihleri arasında birinci sırasında yer alan Ankara Üniversitesi Hukuk Fakültesi'ne
başlamıştır. Eğitimine başladığı bu prestijli fakülteden 2018 yılında mezun olmuş ve ardından
Kırşehir Barosu'nda aktif avukatlık stajına başlamıştır.</p>
                                <Link onClick={ClickHandler} to="/hakkinda" className="theme-btn">Daha Fazla</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-8 col-12">
                        <div className="right-col clearfix">
                            <div>
                                <img src={abimg} alt="" />
                            </div>
                            <div>
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;