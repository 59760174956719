import atimg1 from '../images/team/img-1.jpg'
import atimg2 from '../images/team/img-2.jpg'
import atimg3 from '../images/team/img-3.jpg'
import atimg4 from '../images/team/img-4.jpg'


const Attorneys = [
   {
      Id: '1',
      AtImg: atimg1,
      name: 'Diploma',
      slug: '',
      title: 'Ankara Üniversitesi',
   },
   {
      Id: '2',
      AtImg: atimg2,
      name: 'Katılım Belgesi',
      slug: '',
      title: 'Meslek İçi Eğitim Semineri',
   },
   {
      Id: '3',
      AtImg: atimg3,
      name: 'Katılım Belgesi',
      slug: '',
      title: 'Ankara Üniversitesi/Hukuk Fakultesi',
   },
   {
      Id: '4',
      AtImg: atimg4,
      name: 'Katılım Belgesi',
      slug: '',
      title: 'Kırşehir Barosu Başkanlığı',
   },
   {
      Id: '5',
      AtImg: atimg2,
      name: 'Katılım Belgesi',
      slug: '',
      title: 'Meslek Eğitim Semineri',
   }

]

export default Attorneys;