import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/footer-logo.png'
import Practices from '../../api/Practices';



const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <footer className="site-footer">
            <div className="social-newsletter-area" style={{display:"none"}}>
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="social-newsletter-content clearfix">
                                <div className="social-area">
                                    <ul className="clearfix">
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-facebook"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-pinterest"></i></Link></li>
                                    </ul>
                                </div>
                                <div className="logo-area">
                                    <img src={Logo} alt="" />
                                </div>
                                <div className="newsletter-area">
                                    <div className="inner">
                                        <h3>Newsletter</h3>
                                        <form onSubmit={SubmitHandler}>
                                            <div className="input-1">
                                                <input type="email" className="form-control" placeholder="Email Address *" required="" />
                                            </div>
                                            <div className="submit clearfix">
                                                <button type="submit"><i className="fi flaticon-paper-plane"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-md-6 col-12">
                            <div className="widget about-widget">
                                <div className="widget-title">
                                    <h3>Avukat Büşra Kocaoğlu Ersoy</h3>
                                </div>
                                <p>
2013 yılında Kırşehir Anadolu Öğretmen Lisesi'nden başarıyla mezun olan, üniversite tercihleri arasında birinci sırasında yer alan Ankara Üniversitesi Hukuk Fakültesi'ne başlamıştır. Eğitimine başladığı bu prestijli fakülteden 2018 yılında mezun olmuş ve ardından Kırşehir Barosu'nda aktif avukatlık stajına başlamıştır.</p>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget contact-widget service-link-widget">
                                <div className="widget-title">
                                    <h3>Büro Adresimiz</h3>
                                </div>
                                <ul>
                                    <li>Aşıkpaşa Mah. Dr. İsmail Yağız Cad. Başak Apt. No:11/9
Merkez/KIRŞEHİR</li>
                                    <li><span>Büro Tel:</span> 0386 213 31 69</li>
                                    <li><span>Whatsapp Tel:</span> +90 553 135 01 64</li>
                                    <li><span>Email:</span> avbusrakocaoglu@gmail.com</li>
                                    <li><span>Çalışma Saatlerimiz:</span> 09:00 - 17:00</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-2 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Linkler</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">Anasayfa</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">Hakkında</Link></li>
                                    <li><Link onClick={ClickHandler} to="/services">Hizmetler</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog">Blog</Link></li>
                                    <li><Link onClick={ClickHandler} to="/iletisim">İletişim</Link></li>
                                </ul>
                               
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget line-widget-2">
                                <div className="widget-title">
                                    <h3>Hizmetler</h3>
                                </div>
                                <ul>
                                    {Practices.slice(0, 8).map((practice, Sitem) => (
                                        <li key={Sitem}><Link onClick={ClickHandler} to={`/practice-single/${practice.slug}`}>{practice.sTitle}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="separator"></div>
                        <div className="col col-xs-12">
                            <p className="copyright">Copyright &copy; 2024. Tüm haklar saklıdır.</p>
                            <div className="extra-link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/contact">Gizlilik Politikası</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;