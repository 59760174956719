import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonials/img-1.jpg'
import ts2 from '../../images/testimonials/img-2.jpg'
import ts3 from '../../images/testimonials/img-1.jpg'


const Testimonial2 = (props) => {

    var settings = {
        dots: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const testimonial = [
        {
            tsImg: ts1,
            Des: "Avukat Büşra Hanım, hukuki konularda sunduğu profesyonel ve çözüm odaklı yaklaşımıyla beni etkiledi. Sorunlarımı anlamak ve çözüme kavuşturmak için gerekli özeni ve dikkati gösterdi. Her adımda bana rehberlik ettiği ve güven verdiği için kendisine teşekkür ederim.",
            Title: 'M. K.',
            Sub: "Müvekkil",
        },
        {
            tsImg: ts2,
            Des: "Avukat Büşra Kocaoğlu Ersoy'un, karşılaştığım hukuki sorunlar karşısında bana verdiği destek ve rehberlik, benim için büyük bir güven kaynağı oldu. İlgisi ve özverili çalışması sayesinde, sorunlarımızı etkili bir şekilde çözebildik. Kendisine buradan tekrar teşekkür ederim.",
            Title: 'A. A.',
            Sub: "Müvekkils",
        },
        {
            tsImg: ts3,
            Des: "Sayın Büşra Kocaoğlu Ersoy'un hukuki danışmanlık hizmetlerinden aldığım memnuniyeti ifade etmek isterim. Karşılaştığım hukuki sorunları çözmede gösterdiği özveri ve profesyonellik, beni etkiledi. Kendisiyle iletişimimizin samimi ve saygılı olması, sorunlarımı rahatlıkla paylaşmama olanak sağladı.",
            Title: 'D. M.',
            Sub: "Müvekkil",
        }
    ]
    return (

        <section className={`testimonials-section-s2 section-padding ${props.tClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="testimonials-grids testimonials-slider-s2">
                            <Slider {...settings}>
                                {testimonial.map((tesmnl, tsm) => (
                                    <div className="grid" key={tsm}>
                                        <i className="fi flaticon-left-quote"></i>
                                        <p>{tesmnl.Des}</p>
                                        <div className="client-info">
                                            <div className="img-holder">
                                                <img src={tesmnl.tsImg} alt="" style={{display:"none"}} />
                                            </div>
                                            <h3>{tesmnl.Title}</h3>
                                            <span>{tesmnl.Sub}</span>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial2;