import simg from '../images/service-single/img-1.jpg'
import simg2 from '../images/service-single/img-2.jpg'
import simg3 from '../images/service-single/img-3.jpg'
import simg4 from '../images/service-single/img-4.jpg'
import simg5 from '../images/service-single/img-5.jpg'
import simg6 from '../images/service-single/img-6.jpg'

const Practices = [
    {
        Id: '1',
        sImg: simg,
        sIcon: 'flaticon-parents',
        sTitle: 'Aile Hukuku',
        slug: 'aile-hukuku',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'Aile hukuku, medeni hukukun bir dalı olup, nişanlanma, evlenme koşulları, boşanma şartları ve daha bir çok konu içerir.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '2',
        sImg: simg2,
        sIcon: 'flaticon-wounded',
        sTitle: 'Ceza Hukuku',
        slug: 'ceza-hukuku',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'Ceza muhakemesi hukuku, suç şüphesi altındaki kişinin özgürlüğünü kısıtlayan tedbirlerle ilgilenir.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-015-patient',
    },
    {
        Id: '3',
        sImg: simg3,
        sIcon: 'flaticon-thief',
        sTitle: 'İş Hukuku',
        slug: 'is-hukuku',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'İş hukuku, çalışma yaşamına dair pek çok konuyu düzenleyen bir hukuk dalıdır.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '4',
        sImg: simg4,
        sIcon: 'flaticon-mortarboard',
        sTitle: 'Gayrimenkul Hukuku',
        slug: 'gayrimenkul-hukuku',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'Gayrimenkul hukuku, Eşya Hukuku"nun bir parçası olarak öne çıkan ve toplumun geniş kesimlerini ilgilendiren bir hukuk dalıdır.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '5',
        sImg: simg5,
        sIcon: 'flaticon-architecture-and-city',
        sTitle: 'Trafik Hukuku',
        slug: 'trafik',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'Trafik hukuku, karayollarında meydana gelen trafik olaylarına ilişkin hukuki düzenlemeleri içeren bir alt disiplindir.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '6',
        sImg: simg6,
        sIcon: 'flaticon-save-money',
        sTitle: 'Tazminat Hukuku',
        slug: 'tazminat-hukuku',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'Tazminat hukuku, haksız fiiller veya diğer nedenlerden kaynaklanan tazminat anlaşmazlıklarını içerir.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-017-agreement',
    },
    {
        Id: '6',
        sImg: simg6,
        sIcon: 'flaticon-save-money',
        sTitle: 'Miras Hukuku',
        slug: 'miras-hukuku',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'Avukat Büşra KOCAOĞLU ERSOY, miras davaları ve miras hukuku ile ilgili sorunların çözümü için hukuki destek sağlamaktadır.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-017-agreement',
    },
    {
        Id: '6',
        sImg: simg6,
        sIcon: 'flaticon-save-money',
        sTitle: 'İcra İflas Hukuku',
        slug: 'icra-iflas-hukuku',
        description: 'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2: 'Alacaklı, borcunu zamanında ve gerektiği gibi yerine getirmeyen borçluya karşı alacağını bizzat zor kullanarak elde edemez.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-017-agreement',
    },
]
export default Practices;