import cimg1 from '../images/case-studies/aile-hukuku.jpg'
import cimg2 from '../images/case-studies/ceza-hukuku.jpg'
import cimg3 from '../images/case-studies/gayrimenkul-hukuku.jpg'
import cimg4 from '../images/case-studies/icra-iflas-hukuku.jpg'
import cimg5 from '../images/case-studies/miras-hukuku.jpg'
import cimg6 from '../images/case-studies/tazminat-hukuku.jpg'
import cimg7 from '../images/case-studies/is-hukuku.jpg'
import cimg8 from '../images/case-studies/trafik.jpg'



const Cases = [
    {
        Id: '1',
        cTitle: 'Aile Hukuku', 
        slug: 'aile-hukuku',
        cImg:cimg1,
        cExc:"Aile hukuku, medeni hukukun bir dalı olup, nişanlanma, evlenme koşulları, boşanma şartları ve daha bir çok konu içerir.",
        cDesc:"Aile hukuku, medeni hukukun bir dalı olup, nişanlanma, evlenme koşulları, boşanma"+
        "şartları, mal rejimleri, aile konutu, soybağı, evlat edinme, velayet, çocuğun nafaka"+
        "hakkı, vesayet, kayyımlık, yasal danışmanlık, yardım nafakası gibi konuları içerir."+
        "Boşanma davası, genelde şiddetli geçimsizlik, zina, hayata kast, onur kırıcı davranış,"+
        "suç işleme, terk veya akıl hastalığı gibi sebeplerle açılabilir. Bu dava, hukuki süreç"+
        "yanında psikolojik bir süreci de içerir ve tarafların haklarının korunarak evlilik"+
        "birliğinin başarılı şekilde sonlanması önemlidir. Avukat Büşra KOCAOĞLU ERSOY,"+
        "aile hukuku alanında toplumsal cinsiyet eşitliği ve ev içi şiddet konularında"+
        "uzmanlaşmış olup, bu alandaki çalışmalara aktif katılım sağlamıştır. Fakülte"+
        "yıllarından beri edindiği deneyim ve sertifika programlarıyla, aile hukuku konusunda"+
        "hukuki yardım sunmaktadır."
    },
    {
        Id: '2',
        cTitle: 'Ceza Hukuku', 
        slug: 'ceza-hukuku',
        cImg:cimg2,
        cExc:"Ceza muhakemesi hukuku, suç şüphesi altındaki kişinin özgürlüğünü kısıtlayan tedbirlerle ilgilenir.",
        cDesc:"Ceza muhakemesi hukuku, suç şüphesi altındaki kişinin özgürlüğünü kısıtlayan"+
        "tedbirlerle ilgilenir. Şüphelinin, yakalama, arama ve tutuklama gibi süreçlere itiraz"+
        "etme hakkı vardır. Soruşturma sonucunda savcılık, suç işlendiği kanaatine varırsa,"+
        "şüpheli hakkında ceza davası açar. Türkiye'de ceza davalarına bakan adli ceza"+
        "mahkemeleri suçun niteliğine göre belirlenir ve asliye ceza mahkemeleri ile ağır ceza"+
        "mahkemeleri olmak üzere iki tür mahkemeden oluşur."+
        "Sanık, tüm yargılama aşamalarında hukuki yardım alabilir. Ceza mahkemesi, sanık"+
        "hakkında adli para cezası, hapis cezası veya güvenlik tedbiri gibi hükümler verebilir."+
        "Hapis cezası, belirli şartlarda adli para cezasına çevrilebilir veya ertelenebilir. İstinaf,"+
        "temyiz ve düzeltme aşamalarını geçtikten sonra kesinleşmiş hapis cezasının infaz"+
        "süreci başlar. Hükümlü, suçun niteliğine bağlı olarak koşullu salıverilme veya"+
        "denetimli serbestlik tedbirlerinden yararlanarak cezaevinden tahliye edilebilir. Avukat"+
        "Büşra KOCAOĞLU ERSOY, savunma hakkını temin ederek ceza yargılaması"+
        "sürecine katılan taraflara hukuki yardım sağlar."
    },
    {
        Id: '3',
        cTitle: 'Gayrimenkul Hukuku', 
        slug: 'gayrimenkul-hukuku',
        cImg:cimg3,
        cExc:"Gayrimenkul hukuku, Eşya Hukuku'nun bir parçası olarak öne çıkan ve toplumun geniş kesimlerini ilgilendiren bir hukuk dalıdır.",
        cDesc:"Gayrimenkul hukuku, Eşya Hukuku'nun bir parçası olarak öne çıkan ve toplumun"+
        "geniş kesimlerini ilgilendiren bir hukuk dalıdır. Bu alanda sıkça karşılaşılan"+
        "gayrimenkul davası, mülkiyet hakkına yapılan müdahaleleri ve anlaşmazlıkları"+
        "çözmeyi amaçlar. Mülkiyet hakkı, T.C. Anayasası ve Avrupa İnsan Hakları"+
        "Sözleşmesi tarafından tanınmakta ve korunmaktadır. Gayrimenkul hukuku, tapu iptali"+
        "ve tescili, ortaklığın giderilmesi, muvazaa nedeniyle tapu devir işlemlerinin iptali,"+
        "haksız el atmanın önlenmesi, ecri-misil davası, şufa davası, imar uygulamasından"+
        "kaynaklanan tapu iptal ve tescil davası gibi mülkiyet hakkından kaynaklanan temel"+
        "davaları kapsar."+
        "Gayrimenkul hukuku uyuşmazlıklarında taraflar arasında çözüme ulaşılamadığında,"+
        "mahkemeye taşınması durumunda hukuki destek almak önemlidir. Bu davaların"+
        "mahkemeye taşınması, yüksek mahkeme giderleri ve ücret ödemeleriyle karşılaşma"+
        "riskini içerir. Bu nedenle, gayrimenkul hukuk davalarında hukuki destek alarak işlem"+
        "güvenliği sağlamak büyük önem taşır. Avukat Büşra KOCAOĞLU ERSOY,"+
        "gayrimenkul davalarında titiz bir hazırlık yaparak uyuşmazlıkların çözüm sürecine"+
        "katkıda bulunmaktadır."
    },
    {
        Id: '4',
        cTitle: 'İcra İflas Hukuku', 
        slug: 'icra-iflas-hukuku',
        cImg:cimg4,
        cExc:"Alacaklı, borcunu zamanında ve gerektiği gibi yerine getirmeyen borçluya karşı alacağını bizzat zor kullanarak elde edemez.",
        cDesc:"Alacaklı, borcunu zamanında ve gerektiği gibi yerine getirmeyen borçluya karşı"+
        "alacağını bizzat zor kullanarak elde edemez. Alacağın tahsilatı için cebri icra yoluyla"+
        "zorlama, yasalar çerçevesinde ve kamu otoritesi tarafından gerçekleştirilebilir. İcra ve"+
        "iflas hukuku, özel hukuk ilişkilerinden kaynaklanan para ve teminat alacaklarının"+
        "borçlu tarafından ödenmemesi durumunda, alacaklının talebi üzerine belirlenmiş"+
        "sınırlar içinde kamu otoritesi ve kuvvet yardımıyla nasıl gerçekleştirileceğini ele alır."+
        "Borcun tahsili, borçlunun taşınır ve taşınmaz malları ile üçüncü şahıslardaki hak ve"+
        "alacakları üzerinden yapılabilir."+
        "Ülkemizde, borcun tahsilatı ve ödenmesiyle ilgili tüm prosedür ve kurallar, İcra İflas"+
        "Kanunu tarafından düzenlenmiştir. Alacağın tahsilatı için icra ve iflas daireleri ile"+
        "birlikte icra mahkemeleri görevlendirilmiştir. Gerek duyulması durumunda, polis ve"+
        "diğer kolluk kuvvetleri, icra müdürünün talebi üzerine cebri icra sürecinde görev"+
        "alabilirler."
    },
    {
        Id: '5',
        cTitle: 'Miras Hukuku', 
        slug: 'miras-hukuku',
        cImg:cimg5,
        cExc:"Avukat Büşra KOCAOĞLU ERSOY, miras davaları ve miras hukuku ile ilgili sorunların çözümü için hukuki destek sağlamaktadır.",
        cDesc:"Miras hukuku, gerçek kişinin ölümü veya gaipliği durumunda, mal varlığının, yani"+
        "terekenin Türk Medeni Kanunu tarafından belirlenen şekilde kimlere ve nasıl"+
        "geçeceğini düzenleyen bir hukuk dalıdır. Miras bırakanın mal varlığına ise tereke adı"+
        "verilir."+
        "Bu hukuk dalında sıkça karşılaşılan sorunlar arasında mirastan mal kaçırma (muris"+
        "muvazaası), mirasta saklı pay ve mal paylaşımı, tenkis davası, vasiyetnamenin"+
        "düzenlenmesi ve iptali, mirasın reddi gibi konular bulunmaktadır. Bu tür miras"+
        "hukuku sorunlarını çözen avukatlara genellikle 'miras avukatı' denir. Avukat Büşra"+
        "KOCAOĞLU ERSOY, miras davaları ve miras hukuku ile ilgili sorunların çözümü"+
        "için hukuki destek sağlamaktadır."
    },
    {
        Id: '6',
        cTitle: 'Tazminat Hukuku', 
        slug: 'tazminat-hukuku',
        cImg:cimg6,
        cExc:"Tazminat hukuku, haksız fiiller veya diğer nedenlerden kaynaklanan tazminat anlaşmazlıklarını içerir.",
        cDesc:"Tazminat hukuku, haksız fiiller veya diğer nedenlerden kaynaklanan tazminat"+
        "anlaşmazlıklarını içerir. İki temel türü bulunmaktadır: Maddi tazminat davası,"+
        "bedensel zararlar veya malvarlığı değerlerinin zarar görmesi nedeniyle açılırken;"+
        "manevi tazminat davası, haksız fiil veya işlem sonucu ruhsal bütünlüğü bozulan"+
        "kişinin manevi acılarını gidermeyi amaçlar."+
        "Bu tazminat davaları, bir dizi farklı hukuki nedenlere dayanabilir, örneğin borçlar"+
        "hukukundan kaynaklanan sözleşme ihlali, trafik kazası, doktor uygulama hatası"+
        "(malpraktis), iş hukukundan kaynaklanan ihbar veya kıdem tazminatı, iş kazası, suç"+
        "işlenmesi, boşanma davası gibi durumlar bu kapsamda yer alabilir. Maddi ve manevi"+
        "tazminat davası, çeşitli hukuki nedenlerden kaynaklanan uyuşmazlıkları çözmek"+
        "amacıyla kullanılan bir hukuk dalıdır."
    },
    {
        Id: '7',
        cTitle: 'İş Hukuku', 
        slug: 'is-hukuku',
        cImg:cimg7,
        cExc:"İş hukuku, çalışma yaşamına dair pek çok konuyu düzenleyen bir hukuk dalıdır.",
        cDesc:"İş hukuku, çalışma yaşamına dair pek çok konuyu düzenleyen bir hukuk dalıdır. Bu"+
        "kapsamda iş hukuku, sözleşmenin niteliği ve feshi, işçi ücretleri, yıllık izin ve fazla"+
        "çalışma, kıdem, ihbar ve iş güvencesi gibi konuları ayrıntılı bir şekilde belirler ve"+
        "düzenler. İş Kanunu, işçi ve işveren arasındaki hizmet ilişkilerini ve devlet"+
        "kurumlarıyla olan ilişkilerini düzenleyerek, işçi sendikalarını kapsayan konularda"+
        "araştırma yapar. Ayrıca, çıkarılan işçinin işe iade hakkı ve sonuçları, yeni iş arama"+
        "izni, toplu işçi çıkarma, özürlü ve eski hükümlü çalıştırma zorunluluklarını da iş"+
        "hukukuna uygun bir şekilde belirler."+
        "İş Kanunu, iş hukuku davalarına konu olabilecek tüm uyuşmazlıkları detaylı bir"+
        "biçimde düzenler ve iş mahkemelerinde görülen bu uyuşmazlıkların çözümü için"+
        "yönlendirmeler sunar. İş hukukunun temel amacı, işçi ve işveren arasındaki hizmet"+
        "ilişkilerini adil bir şekilde düzenleyerek, iş yaşamında oluşabilecek uyuşmazlıkları"+
        "önlemeye yönelik uygulamalar geliştirmektir."
    },
    {
        Id: '8',
        cTitle: 'Trafik', 
        slug: 'trafik',
        cImg:cimg8,
        cExc:"Trafik hukuku, karayollarında meydana gelen trafik olaylarına ilişkin hukuki düzenlemeleri içeren bir alt disiplindir.",
        cDesc:"Trafik hukuku, karayollarında meydana gelen trafik olaylarına ilişkin hukuki"+
        "düzenlemeleri içeren bir alt disiplindir. Bu hukuki alan, sürücülerin, yayaların, araç"+
        "sahiplerinin ve trafik polisinin karayolu kullanımıyla ilgili hak ve sorumluluklarını"+
        "belirleyen yasal çerçeveyi kapsar. Trafik hukuku, toplumun genel güvenliği ve"+
        "düzenini sağlamak amacıyla trafikteki davranışları düzenleyerek, bu alanda"+
        "karşılaşılan hukuki meselelere çözümler sunar."+
        "Bu bağlamda, trafik kuralları, trafik işaret ve levhalarına uyma zorunluluğu, sürücü"+
        "belgesi alımı ve iptali, trafik cezaları ve bu cezalara itiraz süreçleri, trafik kazalarında"+
        "tazminat hakları, trafik sigortası şartları gibi konular trafik hukukunun temel"+
        "unsurlarıdır. Ayrıca, trafik polisinin yetki ve görevleri ile trafik denetim noktalarında"+
        "uygulanan prosedürler de bu hukuki düzenlemenin içindedir."+
        "Trafik hukuku, trafik akışının düzenli ve güvenli bir şekilde sürdürülmesini sağlamak"+
        "amacıyla etkin bir hukuki çerçeve sunar. Bu çerçevede, trafikteki hukuki normlara"+
        "uymayan bireylerle ilgili olarak cezai yaptırımlar uygulanır ve trafik kazalarından"+
        "kaynaklanan hukuki ihtilafların çözümüne yönelik mekanizmaları içerir. Bu sayede"+
        "trafik hukuku, karayolu trafiğini düzenlerken aynı zamanda bireylerin haklarını"+
        "koruma ve güvenli bir ulaşım ortamı sağlama hedefine yönelik kapsamlı bir hukuki"+
        "çerçeve sunar."
    },
]

export default Cases;