import React from 'react';
import { Link } from 'react-router-dom';
import Practices from '../../api/Practices';


const Practice = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className={`service-section ${props.sClass}`}>
            <div className="content-area clearfix">
                <div className="left-col">
                    <div className="inner-content">
                        <blockquote>“ Adalet kavramı, insanların temel hak ve özgürlüklerini korumanın yanı sıra, toplumları daha adil, dürüst ve insan haklarına saygılı kılan bir değerdir. Adaletin bu önemli rolü, insanların bir arada uyum içinde yaşamasını sağlar ve toplumları daha güçlü kılar.”</blockquote>

                        <h4>Anonim</h4>
                        <span>Adalet</span>
                    </div>
                </div>
                <div className="right-col">
                    <div className="section-title-s2">
                        <div className="icon">
                            <i className="fi flaticon-balance"></i>
                        </div>
                        <span>Avukar Büşra Kocaoğlu Ersoy</span>
                        <h2>Hizmetler</h2>
                        <p>Yılların tecrübesi ile hizmetinizdeyim. İletişim bölümünden veya direk telefon ile randevu alabilir ve iletişime geçebilirsiniz.</p>
                    </div>
                    <div className="service-grids clearfix">
                        {Practices.map((practice, Pitem) => (
                            <div className="grid" key={Pitem}>
                                <i className={`fi ${practice.sIcon}`}></i>
                                <h3><Link onClick={ClickHandler} to={`/hizmet-detay/${practice.slug}`}>{practice.sTitle}</Link></h3>
                                <p>Detaylı bilgi için tıklayın</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Practice;