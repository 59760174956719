import React from 'react'
import abimg from '../../images/about.jpg'
import signature from '../../images/signature.png'
import { Link } from 'react-router-dom';

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-12">
                        <div className="left-col">
                            <div className="section-title">
                                <span>Avukat Büşra Kocaoğlu Ersoy</span>
                                <h2>Hakkında</h2>
                                <p>2013 yılında Kırşehir Anadolu Öğretmen Lisesi'nden başarıyla mezun olan, üniversite
tercihleri arasında birinci sırasında yer alan Ankara Üniversitesi Hukuk Fakültesi'ne
başlamıştır. Eğitimine başladığı bu prestijli fakülteden 2018 yılında mezun olmuş ve ardından
Kırşehir Barosu'nda aktif avukatlık stajına başlamıştır.
Halihazırda, Kırşehir Barosu'na kayıtlı olarak avukatlık mesleğini başarıyla icra etmektedir.
Hukuk fakültesindeki eğitim hayatına adım attığı dönemden bu yana, yargının temel
unsurlarından biri olan avukatlık mesleğine büyük bir tutku ve özveriyle bağlıdır. Bu süreçte,
kendi hedefleri doğrultusunda kararlı adımlar atarak başarıya ulaşmıştır. Bu doğrultuda
Barolar Birliği ve Kırşehir Barosu tarafından gerçekleştirilen eğitimlere katılmış ve çeşitli
sertifikalar almaya hak kazanmıştır.
Üniversite yıllarında Ankara'daki saygın hukuk ofislerinde çalışarak, mesleki anlamda
deneyim kazanmış ve bu süreçte teoride öğrendiklerini uygulamaya aktarmış ayrıca yine
fakülte eğitimi sürerken Kadın Hakları, İşçi Hakları alanlarında spesifik olarak eğitim almış ve
sertifika almaya da hak kazanmıştır. Bu şekilde kendisini sürekli olarak geliştirmiştir.
Avukatlık ruhsatını aldıktan sonra ise şehrinin başarılı avukatlarından biri haline gelmiştir.
Avukat-müvekkil ilişkisi karşılıklı güven esasına dayanır. Avukat Büşra KOCAOĞLU ERSOY
mesleğin temel ilkelerinin bilincinde olup, vekaletini aldığı müvekkilleri ile arasındaki sır
saklama yükümlülüğüne özen göstermekte ve şeffaflık ilkesi gereği müvekkillerini dava
süreci ile yapılan işin aşamaları hakkında sürekli bir bilgi akışıyla bilgilendirmektedir.
Avukat Büşra KOCAOĞLU ERSOY, Ceza Hukuku, Aile Hukuku, Miras Hukuku, Gayrimenkul
Hukuku,Tazminat Hukuku, İş Hukuku, İcra İflas Hukuku ve Trafik Hukuku başta olmak üzere
birçok hukuk alanında avukatlık faaliyetini yürütmektedir.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-12">
                        <div className="mid-col">
                            <img src={abimg} alt="" />
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default About;