import React from 'react'
import ContactFrom from '../ContactFrom';

const Contactpage = (props) => {
    return (
        <div>
            <section className="contact-section-s3">
                <div className="content-area clearfix">
                    <div className="contact-info-col">
                        <div className="contact-info">
                            <ul>
                                <li>
                                    <i className="fi flaticon-home-3"></i>
                                    <h4>Büro Adresi</h4>
                                    <p>Aşıkpaşa Mah. Dr. İsmail Yağız Cad. Başak Apt. No:11/9 Merkez/KIRŞEHİR</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-email"></i>
                                    <h4>Email</h4>
                                    <p>bilgi@busrakocaogluersoy.com</p>
                                </li>
                                <li>
                                    <i className="fi flaticon-support"></i>
                                    <h4>Telefon</h4>
                                    <p><a href="tel:+905531350164">+90 553 135 0164</a></p>
                                </li>
                                <li>
                                    <i className="fi flaticon-clock"></i>
                                    <h4>Büro Saatlerimiz</h4>
                                    <p>P.Tesi-Cuma: 09:00 – 17:00</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contact-form-col">
                        <div className="section-title-s2">
                            <div className="icon">
                                <i className="fi flaticon-balance"></i>
                            </div>
                            <span>İLETİŞİM FORMU</span>
                            <h2>Davanız mı var? <br />Hemen Bize Ulaşın.</h2>
                            <p>Bize yazmanızdan memnuniyet duyarız. En kısa zamanda size geri dönüş sağlayacağız.</p>
                        </div>

                        <div className="contact-form">
                            <ContactFrom />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-map-section section-padding pb-0">
                <div className="contact-map">
                    <iframe title='contact-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11910.326491042591!2d34.153432136325264!3d39.141074641887975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b8fa13d86e0d5ab%3A0xd2fc2b745bc878a0!2zQXZ1a2F0IELDvMWfcmEgS29jYW_En2x1IEVyc295IEh1a3VrIELDvHJvc3U!5e0!3m2!1sen!2sde!4v1710673478176!5m2!1sen!2sde" allowfullscreen></iframe>
                </div>
            </section>
        </div>
    )
}

export default Contactpage;