import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import Logo from '../../images/logo-2.png'
import HeaderTopbar from '../HeaderTopbar';


const Header2 = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [cartActive, setcartState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const { carts } = props;

    return (
        <header id="header" className={`site-header header-style-1 ${props.hclass}`}>
            <HeaderTopbar container={'container-fluid'} />
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo} alt="" /></Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                            <li><Link onClick={ClickHandler} to="/">Anasayfa</Link></li>
                            <li><Link onClick={ClickHandler} to="/hakkinda">Hakkında</Link></li>
                            <li><Link onClick={ClickHandler} to="/hizmetler">Hizmetler</Link></li>
                            <li><Link onClick={ClickHandler} to="/blog">Blog</Link></li>
                            <li><Link onClick={ClickHandler} to="/iletisim">İletişim</Link></li>
                            <li className="menu-item-has-children" style={{display:"none"}}>
                                <Link onClick={ClickHandler} to="/about">Kurumsal</Link>
                                <ul className="sub-menu" >
                                    <li><Link onClick={ClickHandler} to="/about">About Company</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about-me">About Me</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials-s2">Testimonials Style 2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team">Team</Link></li>
                                    <li><Link onClick={ClickHandler} to="/attorneys-single/Michel-Troat">Team Single</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                    <li><Link onClick={ClickHandler} to="/404">404</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                </ul>
                            </li>
                           
                        </ul>
                    </div>

                    <div className="cart-search-contact">
                        <div className="header-search-form-wrapper">
                            <button onClick={() => setMenuState(!menuActive)} className="search-toggle-btn"><i
                                className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}></i></button>
                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                <form onSubmit={SubmitHandler}>
                                    <div>
                                        <input type="text" className="form-control"
                                            placeholder="Arama..." />
                                        <button type="submit"><i
                                            className="ti-search"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};


export default connect(mapStateToProps, { removeFromCart })(Header2);